import React, {useState} from "react"
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Navigation } from 'swiper'
import 'swiper/css';
import Arr from "../../images/Arrows/Polygon 1.png"
import AnchorLink from "react-anchor-link-smooth-scroll"

import Slide_1 from "../../images/Slider/Денежный поток.jpg";
import Slide_2 from "../../images/Slider/Денежный поток 1.jpg";
import Slide_3 from "../../images/Slider/Денежный поток 2.jpg";
import Slide_4 from "../../images/Slider/Денежный поток 3.jpg";
import Slide_5 from "../../images/Slider/Денежный поток 4.jpg";
import Slide_6 from "../../images/Slider/Денежный поток 5.jpg";
import Slide_7 from "../../images/Slider/Денежный поток 6.jpg";
import Slide_8 from "../../images/Slider/Денежный поток 7.jpg";
import Slide_9 from "../../images/Slider/Денежный поток 8.jpg";
import Slide_10 from "../../images/Slider/Денежный поток 9.jpg";
import Slide_11 from "../../images/Slider/Денежный поток 10.jpg";
import Slide_12 from "../../images/Slider/Денежный поток 11.jpg";
import Slide_13 from "../../images/Slider/Денежный поток 12.jpg";

const Intro = () => {
  const [isShow, setIsShow] = useState(false)
    return (
        <section className="intro" id="intro">

            <div className="intro__wrapper container">

              <div className="intro__block">

                    <div className="intro__content">
                      <div className="intro__title">
                        ИГРЫ, КОТОРЫЕ УЧАТ ТОМУ,
                        ЧЕМУ
                        <br/>
                        <span>НЕ УЧИЛИ В ШКОЛЕ</span>
                      </div>
                    </div>

                  <div className="intro__subtitle">
                    <div className="intro__subtitle--f">
                      Как быть богатым
                    </div>
                    <div className="intro__subtitle--s">
                      Как быть свободным
                    </div>
                    <div className="intro__subtitle--t">
                      Как быть счастливым
                    </div>
                  </div>

                <Swiper
                  // spaceBetween={50}
                  // slidesPerView={3}
                  navigation={true}
                  modules={[Autoplay, Navigation]}
                  loop={true}
                  autoplay={
                    { delay: 2000,
                      disableOnInteraction: false
                    }
                  }
                >
                  <SwiperSlide>
                    <img src={Slide_1} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Slide_2} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Slide_3} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Slide_4} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Slide_5} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Slide_6} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Slide_7} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Slide_8} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Slide_9} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Slide_10} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Slide_11} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Slide_12} />
                  </SwiperSlide>
                  <SwiperSlide>
                    <img src={Slide_13} />
                  </SwiperSlide>
                </Swiper>

              </div>

            </div>

          <AnchorLink
            href="#producttitle"
            onClick={() => setIsShow(false)}
            className="btn__go btn"
          >
            Сразу перейти к покупке
          </AnchorLink>
          <div className="btn__arr--wrap">
            <img className="btn__arr" src={Arr} />
          </div>
        </section>
    )
}
export default Intro
