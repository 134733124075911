import* as React from "react"

const Button = ({ onClickButton }) => {
    return (
        <button
          className="btn"
          onClick={onClickButton}
        >
            заказать
        </button>
    )
}
export default Button
