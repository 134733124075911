import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ReactModal from "react-modal";
import emailjs from '@emailjs/browser';
import ToastComponent from '../toastComponent';
import { apiKey } from "../../../emailkey";
import "./index.scss";

function Modal({ gameName, isOpen, toggleModal }) {
    const { register, handleSubmit, reset, formState: {errors} } = useForm({mode: "onChange"});

    const [isLoader, setIsLoader] = useState(false);

    const onSubmit = async data => {
        const newData = {...data, gameName}
        setIsLoader(true);
        await emailjs.send(apiKey.SERVICE_ID, apiKey.TEMPLATE_ID, newData, apiKey.USER_ID)
            .then((result) => {
                if(result.status === 200) {
                    setIsLoader(false);
                    reset();
                    ToastComponent("success", "Данные успешно отправлены");
                    toggleModal()
                }
            }, (error) => {
                setIsLoader(false);
                reset();
                ToastComponent("error", "Ошибка отправления, повторите попытку");
                toggleModal()
            })
        reset();
    };

    return (
        <>
            <ReactModal
                isOpen={isOpen}
                closeTimeoutMS={200}
                ariaHideApp={false}
                onRequestClose={toggleModal}
            >
                <div className="modal-close" onClick={toggleModal}>
                    <div className="modal-close__btn" />
                </div>
                <div className="form-modal">
                    <div className="form">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className="form__title">
                                Заказать: {gameName}
                            </div>
                            <label>
                                <input
                                  {...register("QUANTITY")}
                                  type="text"
                                  placeholder="Количество"/>
                            </label>
                            <label>
                                <input
                                  {...register("country")}
                                  type="text"
                                  placeholder="Страна"/>
                            </label>
                            <label>
                                <input
                                  {...register("Code")}
                                  type="text"
                                  placeholder="Почтовый индекс"/>
                            </label>
                            <label>
                                <input
                                  {...register("ADDRESS")}
                                  type="text"
                                  placeholder="Почтовый адрес"/>
                            </label>
                            <label>
                                <input
                                  {...register("name")}
                                  type="text"
                                  placeholder="Ф.И.О."/>
                            </label>
                            <label>
                                <input
                                  {...register("phone")}
                                  type="text"
                                  placeholder="Контактный телефон в международном формате с кодом страны (обязательно)"/>
                            </label>
                            <label style={{position: "relative"}}>
                                <input
                                    className="form__input"
                                    {...register("email", {
                                        required: "Email обязательное поле",
                                        pattern: {
                                            value: /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                                            message: "Введите корректный email"
                                        }
                                    })}
                                    type="email"
                                    placeholder="Введите email"
                                />
                                {errors?.email && (
                                    <div className="form__input-error">{errors.email.message}</div>
                                )}
                            </label>
                            <button
                                className="btn btn_secondary"
                            >
                                Отправить
                            </button>
                            {isLoader && <div className="page-loader" />}
                        </form>
                    </div>
                </div>
            </ReactModal>
        </>
    )
}

export default Modal;
