import * as React from "react"

const Producttitle = () => {
    return (
        <section className="producttitle" id="producttitle">
            <div className="container">
                <h1 className="producttitle__title">ВЫБРАТЬ И КУПИТЬ <span>ИГРУ</span></h1>
            </div>
        </section>
    )
}

export default Producttitle
