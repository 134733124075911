import React from "react";
import { toast } from "react-toastify";
import CheckIcon from "../../images/icons/toastcheck.svg";
import ErrorIcon from "../../images/icons/toasterror.svg";

function ToastComponent(type, text) {
  const icons = {
    success: CheckIcon,
    error: ErrorIcon,
  };

  return toast(
    () => (
      <>
        <img src={icons[type]} />
        <span>{text}</span>
      </>
    ),
    {
      position: "top-right",
      // hideProgressBar: true,
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,

    },
  );
}

export default ToastComponent;
