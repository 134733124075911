import React, {useState, useEffect} from "react"
import "./footer.scss"

const Footer = () => {
  return (
    <footer>
      <div  className="footer">
        <div className="container">
          <div>Сайт разработан под пальмами Доминиканы</div>
          <div className="footer__wrap">© financial-games.shop, 2023 </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
