import React, { useState } from "react"
import classNames from "classnames"

import Button from "../button/button"
import Slider from "../slider/slider"
import Modal from "../modal"
import Forkids from "../forkids/forkids"
import Footer from "../footer/footer"

const Products = ({ data, kidsData }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [gameName, setGameName] = useState('');
  const [state, setState] = useState({
    "1": {
      isHidden: false,
    },
    "2": {
      isHidden: false,
    },
    "3": {
      isHidden: false,
    },
    "4": {
      isHidden: false,
    },
    "5": {
      isHidden: false,
    },
    "6": {
      isHidden: false,
    },
    "7": {
      isHidden: false,
    },
    "8": {
      isHidden: false,
    },
    "9": {
      isHidden: false,
    },
    "10": {
      isHidden: false,
    },
    "11": {
      isHidden: false,
    },

  });

  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const toggleHidden = (e, id) => {
    const newData = {...state, ...state[id].isHidden = !state[id].isHidden}
    setState(newData)
    // e.preventDefault()
  }

  const renderItem = (item) => {
    return <>
      <div
        id={item.id}
        key={item.id}
      >
        <div
          className={classNames({
            'product__block': true,
            'product__block--reverse': item.isActive
          })}
        >
          <div className="product__content">
            <div className="product__subtitle" dangerouslySetInnerHTML={{ __html: item.block }} />
            <div className="product__description" dangerouslySetInnerHTML={{ __html: item.info }} />
            <div className="product__data">
              <div className="product__price">{item.price}</div>
              <Button onClickButton={() => {
                setGameName(item.name)
                toggleModal()
              }}/>
              <>
                <button
                  className={classNames({
                    'product__btn--open': true,
                    'product__btn--absent': item.absent
                  })}
                  onClick={(e) => toggleHidden(e, item.id)}
                >
                  {state[item.id].isHidden ? "Cкрыть описание" : "Читать дальше..."}
                </button>
              </>
            </div>
          </div>

          <div className="product__slide">
            <Slider item={item} />
          </div>
        </div>

        <div
          className={classNames({
            'product__hidden': true,
            'open': state[item.id].isHidden
          })}
          dangerouslySetInnerHTML={{ __html: item.hidden }}
        />
      </div>
    </>
  };

  return (
    <section className="product">
      <div className="container">
        {data.map(item => renderItem(item))}
        <Forkids />
        {kidsData.map(item => renderItem(item))}
        <Footer />
        <Modal gameName={gameName} toggleModal={toggleModal} isOpen={isOpen} />
      </div>
    </section>
  )
}

export default Products
