import * as React from "react"
import Use from "../../images/useful.jpeg"
import Box from "../../images/BOX.png"
import Useful1 from "../../images/useful1.jpeg"
import Useful2 from "../../images/useful2.jpeg"
import Useful3 from "../../images/useful3.jpg"
import "./useful.scss"

const Useful = () => {
  return (
    <div className="container useful" id="useful">
      <div className="useful__text">Существует множество интересных настольных игр. В основном все они созданы для детей и предназначены чисто для развлечения. Сюда же относятся и деловые настольные игры, такие как «Менеджер» и «Монополия». Они широко известны и не нуждаются в представлении. Наверно, каждый из нас хотя бы раз в жизни играл в такую игру. В детстве они доставляли нам море удовольствия, не так ли? Но теперь мы выросли, и они больше не вызывают у нас интереса. И это не удивительно: взрослым некогда играть настольные игры. У них на это просто нет времени! Да и зачем нам это? Если у нас теперь <strong>вся жизнь – это одна большая финансовая игра!</strong> И идет эта игра <strong>не на игрушечные, а на реальные деньги...</strong> Тут уже, как говорится, <strong> вообще не до игр… </strong> </div>

      <div className="useful__bad">
        <img src={Useful1}/>
        <img src={Useful2}/>
      </div>

      <div className="useful__text">Но что, если я вам скажу, что существуют настольные бизнес-игры, созданные специально для взрослых? И они интересны до такой степени, что взрослые солидные люди проводят за этими играми весь день и не могут от них оторваться! </div>

      <img className="useful__img" src={Use} />

      <div className="useful__text">Что, если я вам скажу, что эти игры не просто увлекательны, но еще и полезны! Полезны на столько, что взрослые люди, не просто с удовольствием играют в них, а организовывают ради этого целые бизнес-клубы, собираются в этих клубах на выходные и платят реальные деньги за то, чтобы поучаствовать в этих играх и чему-нибудь из них научиться!</div>

      <img className="useful__img" src={Useful3} />

      <div className="useful__text">Именно такие игры представлены на нашем сайте! А если вы мне не верите, - значит, вы просто ничего не знаете об играх <strong>Cashflow (Денежный поток) и Rat Race (Крысиные бега). </strong></div>

      <img className="useful__img" src={Box}/>

      <div className="useful__text"><strong>Cashflow (Денежный поток) и Rat Race (Крысиные бега)</strong> – это нечто гораздо большее, чем просто настольные игры, - это настоящие бизнес-тренажеры для умных и амбициозных людей, с помощью которых вы можете накачать свои интеллектуальные мускулы и добиться успеха в жизни.</div>

      <div className="useful__text">
        Это совершенно уникальный интеллектуальный продукт, созданный известными писателями, инвесторами и бизнесменами <strong>Шерон Лечтер и Робертом Кийосаки</strong> специально для того, чтобы вы могли обучиться основам финансовой грамотности и правильного распоряжения своими деньгами.
      </div>
      <div className="useful__text">
        Эти игры созданы для тех, кто хочет от жизни чего-то гораздо большего, чем просто «хорошая работа», «стабильная зарплата» и одна «неделя отпуска» раз в году, - они созданы для тех, кто мечтает не о «хорошей работе», - а о «собственном бизнесе», не о «стабильной зарплате», - а о «бешеной прибыли», не об «отпуске на берегу моря», - а о свободе от работы на всю оставшуюся жизнь!
      </div>
      <div className="useful__text">
        Благодаря этим гениальным бизнес-тренажерам вы сможете в увлекательной игровой форме изучить основы финансовой грамотности, развить свое предпринимательское мышление, найти свою нишу и перейти от работы за зарплату - к частному бизнесу.
      </div>
      <div className="useful__text">
        Вы поймете, как мыслят и принимают решения предприниматели и бизнесмены, увидите, чем они отличаются от простых рабочих и служащих. Вы узнаете, в чем секрет их успеха, и сможете его повторить!
      </div>
      <div className="useful__text">
        Вы получите сотни четких и ясных рецептов, как начать свое дело, и создать свой денежный поток. Вы увидите сотни возможностей, которые каждый день проплывают мимо вас, а вы их просто не замечаете.
      </div>
      <div className="useful__text">
        Вы научитесь генерировать идеи, делать деньги на ровном месте и извлекать прибыль буквально из воздуха! Вы сможете перестроить свою жизнь по системе <strong>cash flow</strong> и станете хозяином своего времени, своих денег и своей жизни.
      </div>
    </div>
  )}
export default Useful
