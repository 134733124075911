import * as React from "react"
import { ToastContainer } from 'react-toastify';

import Layout from "../components/layout"
import Seo from "../components/seo"
import Intro from "../components/intro/intro"
import Producttitle from "../components/producttitle/producttitle"
import Products from "../components/products/products"
import Useful from "../components/useful/useful"

import { productsData } from "../data/productsData"
import { kidsData } from "../data/kidsData"

import "../components/style.scss"

import "react-toastify/dist/ReactToastify.css";




const IndexPage = () => (
  <Layout>
    <Seo title="Мир Настолок" />
    <Intro />
    <Useful />
    <Producttitle />
    <Products data={productsData} kidsData={kidsData} />
    {/*<Forkids />*/}
    {/*<Products data={kidsData} />*/}
    <ToastContainer />
  </Layout>
)

export default IndexPage
